import React from 'react';
import certImg from './Responsive.jpg';
import projImg from './newsapp.jpg';

export default function Portfolio() {
  const cardBorderColor = '#333'; // Same color as the "Portfolio" text

  return (
    <div className="container" style={{ minHeight: '80vh', paddingTop: '70px' }}>
      <h1 className="my-4" style={{ fontSize: '2.5rem', fontWeight: 'bold', textAlign: 'center', letterSpacing: '2px', color: cardBorderColor }}>
        <strong>Portfolio</strong>
      </h1>

      <div className="row row-cols-1 row-cols-md-2 g-4">
        {/* Projects Card */}
        <div className="col d-flex justify-content-center">
          <div 
            className="card"
            style={{
              maxWidth: '400px',
              borderRadius: '10px',
              overflow: 'hidden',
              transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              backgroundColor: '#f8f9fa',
              border: `2px solid ${cardBorderColor}`, // Matching border color
            }}
            onClick={() => { window.open('https://github.com/subedibiraj', '_blank'); }}
            onMouseOver={(e) => {
              e.currentTarget.style.transform = 'scale(1.05)';
              e.currentTarget.style.boxShadow = '0 20px 40px rgba(0, 0, 0, 0.2)';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.boxShadow = '0 10px 20px rgba(0, 0, 0, 0.1)';
            }}
          >
            <img src={projImg} className="card-img-top" alt="Project" style={{ height: '200px', objectFit: 'cover' }} />
            <div className="card-body text-center">
              <h5 className="card-title"><strong>Projects</strong></h5>
              <p className="card-text">Explore the projects I've worked on for learning, university projects, and certifications.</p>
              <button className="btn btn-outline-dark mt-3" style={{ transition: 'background-color 0.3s' }}>
                View Projects
              </button>
            </div>
          </div>
        </div>

        {/* Certifications Card */}
        <div className="col d-flex justify-content-center">
          <div 
            className="card"
            style={{
              maxWidth: '400px',
              borderRadius: '10px',
              overflow: 'hidden',
              transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              backgroundColor: '#f8f9fa',
              border: `2px solid ${cardBorderColor}`, // Matching border color
            }}
            onClick={() => { window.open('https://www.freecodecamp.org/masterb', '_blank'); }}
            onMouseOver={(e) => {
              e.currentTarget.style.transform = 'scale(1.05)';
              e.currentTarget.style.boxShadow = '0 20px 40px rgba(0, 0, 0, 0.2)';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.boxShadow = '0 10px 20px rgba(0, 0, 0, 0.1)';
            }}
          >
            <img src={certImg} className="card-img-top" alt="Certification" style={{ height: '200px', objectFit: 'cover' }} />
            <div className="card-body text-center">
              <h5 className="card-title"><strong>Certifications</strong></h5>
              <p className="card-text">Explore the certifications I've earned to enhance my skills in various domains.</p>
              <button className="btn btn-outline-dark mt-3" style={{ transition: 'background-color 0.3s' }}>
                View Certifications
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
