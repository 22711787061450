import React from 'react';


export default function CV() {
  const googleDrivePDFLink = 'https://drive.google.com/file/d/1-H4ilToYsKVJ7ryaHFqW78mITMUAKSoi/view?usp=drive_link';
  return (
    <div className="container-fluid d-flex flex-column align-items-center justify-content-center" style={{ paddingTop: '70px', minHeight: '100vh' }}>
      <div className="card border-0 shadow-lg p-5 mb-5" style={{ maxWidth: "25rem", borderRadius: '30px', overflow: 'hidden' }}>
        <div className="card-body text-center">
          <h2 className="card-title mb-4" style={{fontSize:'2rem'}}><strong>Resume/CV</strong></h2>
          <p className="card-text" style={{ fontSize: '1.4rem', lineHeight: '1.6', color: '#555' }}>Explore my comprehensive professional portfolio and discover the skills and experiences that define my journey.</p>
        </div>
      </div>
      <a href={googleDrivePDFLink} download="BirajSubedi.pdf" className="btn btn-dark btn-lg my-4" style={{ borderRadius: '30px', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)', textDecoration: 'none' }}>Download Resume</a>
    </div>
  );
}
